import './src/stylesheets/styles.scss'
import '@fontsource/licorice'

import { stickUtmParameters } from './src/utm'
import { freshpaint, freshpaintTrack } from './src/freshpaint'
import { amplitudeAnalytics } from './src/amplitude'

export const onClientEntry = (): void => {
  freshpaint()
  stickUtmParameters()
  amplitudeAnalytics()
}

export const onRouteUpdate = () => {
  setTimeout(() => {
    freshpaintTrack('engaged_page_views')
  }, 30000)
}
